<template>
  <div class="About">
    <div v-loading="loading"></div>
    <div class="About-bg"></div>
    <div v-if="!loading" class="About-content">
      <div class="About-banner cur" @click="GoHome">
        <img v-if="LandingPageList.landingPage.length > 0" class="About-banner-bg About-banner-bg-p" :src="LandingPageList.landingPage[0].imgUrl">
        <img v-else class="About-banner-bg About-banner-bg-p" src="@/assets/PC/home/about-banner.png" alt="">
        
        <img v-if="LandingPageList.landingPage.length > 0" class="About-banner-bg About-banner-bg-m" :src="LandingPageList.landingPage_m[0].imgUrl">
        <img v-else class="About-banner-bg About-banner-bg-m" src="@/assets/PC/home/about-banner.png" alt="">
      </div>
      <div class="About-section flex6">
        <div class="About-section-item About-section-left">
          <div class="About-section-title">
            <h1 class="flex0 co-fff">学习中心
              <div class="san"></div>
            </h1>
            <div class="About-section-i">
              <div v-if="!IsLibrarydata" class="About-studys flex6">
                <div v-for="item in LandingPageList.respLibraryDatas" :key="item.id" class="About-studys-item">
                  <div @click="GoDetail(item.id)" style="height: 0; padding-bottom: 56%; overflow: hidden;">
                    <img class="About-studys-item-img cur" :src="item.imgUrl" alt="">
                  </div>
                  <div style="padding: 10px">
                    <h4 @click="GoDetail(item.id)" class="hidden cur"> {{ item.title }} </h4>
                    <p class="hidden mb co-999"> {{ item.releaseTimeStr}} </p>
                    <p @click="GoDetail(item.id)" class="About-studys-item-introduction hidden3 co-999 cur"> {{ item.introduction }} </p>
                    <div class="flex4 icon-operate" >
                      <div class="mr icon-i"><img class="icon-pv" src="../assets/PC/studyF/study_type_k.png" alt=""> {{item.pv}}</div>
                      <div @click="GoLogin" class="mr icon-i cur"><img class="icon-like" src="../assets/PC/studyF/Like.png" alt=""> {{item.praiseNum}}</div>
                      <div @click="GoLogin" class="mr icon-i cur"><img class="icon-collect" src="../assets/PC/studyF/study_type_xk.png" alt=""></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="Data-null flex0">
                暂无数据
              </div>
              <div class="goMust"><a class="flex5" :href="studyLink" target="_blank">进入了解更多<van-icon name="arrow" /></a></div>
            </div>
          </div>
        </div>
        <div class="About-section-item About-section-right">
          <div class="About-section-title">
            <h1 class="flex0 co-fff">热门话题
              <div class="san"></div>
            </h1>
            <div class="About-section-i">
              <ul v-if="!IsForumdata">
                <li v-for="item in LandingPageList.respForumLandings" :key="item.id" class="hidden cur" @click="GoDetail1(item.id)">
                  <van-icon name="fire" class="co-voi"/>
                  <b class="co-voi"> {{ item.browseCount }} </b> 
                  {{ item.title }}
                </li>
              </ul>
              <div v-else class="Data-null flex0">
                暂无数据
              </div>
              <div class="goMust"><a class="flex5" :href="topicLink" target="_blank">进入了解更多<van-icon name="arrow" /></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetLandingPage } from "@/api/Home.js";
import { ApiToken } from '@/utils/ApiToken'

export default {
  name: 'landingpage',
  data() {
    return {
      loading: true,
      topicLink: ApiToken.shareUrl + "Home/Forum",
      studyLink: ApiToken.shareUrl + "Home/Fence/FenceIndex",
      LandingPageList: {
        landingPage: [],
        landingPage_m: [],
        respForumLandings: [],
        respLibraryDatas: [],
      },// !落地页数据
      IsForumdata: false,   //!热门话题是否有数据
      IsLibrarydata: false,   //!习剑是否有数据
    }
  },
  mounted() {
    const Id = { "Industry": this.$route.query.Industry } 
    GetLandingPage(Id).then(res => {
      if(res.status == 1) {
        this.LandingPageList = res.data;
        if(this.LandingPageList.respForumLandings.length < 1) {
          this.IsForumdata = true;
        }
        if(this.LandingPageList.respLibraryDatas.length < 1) {
          this.IsLibrarydata = true;
        }
        this.loading = false;
      }
    })
  },
  methods: {
    // !去首页
    GoHome() {
      this.$router.push('/');
    },
    // !去登录
    GoLogin() {
      this.$router.push('/Login')
    },
    // !去详情页
    GoDetail(id) {
      this.$router.push("/Home/FenceDetail?id=" + id);
    },
    // !去驭剑详情页
    GoDetail1(id) {
      this.$router.push("/Home/forumDetails?id=" + id);
    }
  },
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .About {
    min-height:100vh; background: #F5F5F9; padding-bottom: 50px;

    .About-banner .About-banner-bg, .About-studys-item-img {width: 100%;}

    .About-bg {height: 350px; background: #3a0951;}
    .About-content {width: 1200px; background: #fff; border-radius: 5px; margin: 0 auto; margin-top: -300px; padding: 40px;}

    .About-banner {position: relative; margin-bottom: 40px;}
    .About-banner .About-banner-bg-m {display: none;}

    .goHome {width: 150px; height: 50px; background-image: linear-gradient(to left, rgba(255,255,255,0),rgba(107,17,170,1)); position: absolute; left: 30px; top: 50%;}
    .goHome b {margin-right: 15px;}
    .About-section-item {width: 49%;}
    .About-section-title {height: 100%;}
    .About-section-title h1 {font-size: 18px; width: 100px; height: 40px; background: rgb(107, 17, 170); line-height: 40px; position: relative;}
    .About-section-title h1 .san {width: 0; height: 0; border-left: 15px solid transparent; border-right: 15px solid transparent; border-bottom: 40px solid  rgb(107, 17, 170); position: absolute; top: 0; right: -15px;}
    .About-section-i {border: 1px #ccc solid; padding: 20px; height: calc( 100% - 40px);}

    .About-studys-item {width: 49%; background: #f6f8fa;}
    .About-studys-item-introduction {margin-bottom: 5px; height: 57px;}

    .About-section-i ul {background: #f6f8fa; padding: 5px 15px 5px 15px;}
    .About-section-i ul li {line-height: 4; border-bottom: 1px #ccc solid;}
    .About-section-i ul li:last-of-type {border: 0;}
    .goMust {margin-top: 15px;}

    .icon-operate {margin-bottom: 15px;}
    .Data-null { height: calc( 100% - 34px );}

    .icon-i {margin-right: 20px;}
  }

  @media (max-width: 750px) {
    .About {
      .About-bg {height: vw(250)}
      .About-content {width: 94%; margin: 0 auto; margin-top: vw(-150); padding: vw(15);}
      .About-banner {margin-bottom: vw(20);}

      .About-banner .About-banner-bg-m {display: block;}
      .About-banner .About-banner-bg-p {display: none;}

      .goHome {width: vw(250); height: vw(70); margin-top: vw(-35);}
      .About-section {display: block;}
      .About-section-item {width: 100%; margin-bottom: vw(30);}

      .About-section-title h1 {font-size: vw(30); width: vw(180); height: vw(60);line-height: vw(60);}
      .About-section-title h1 .san {border-left: vw(20) solid transparent; border-right: vw(20) solid transparent; border-bottom: vw(60) solid  rgb(107, 17, 170); position: absolute; right: vw(-20);}
    
      .About-section-i { padding: vw(30);}
      .icon-operate {margin-bottom: 0;}
      .icon-i {margin-right: vw(6);}
    }
  }

</style>